import React from "react";
import { SignIn } from "aws-amplify-react";
import './style.scss';

export class CustomSignIn extends SignIn {
  constructor(props: any) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }

  showComponent() {
    return (
      <div className="CustomSignIn">
        <form>
          <div id="input-row">
            <input id="username" key="username" name="username"
              onChange={this.handleInputChange} type="text" placeholder="Username"/>
          </div>
          <div className="input-row">
            <input id="password" key="password" name="password"
              onChange={this.handleInputChange} type="password" placeholder="Password"/>
          </div>
          <div className="input-row">
            <button type="button" onClick={event => super.signIn(event)}>
              Login
            </button>
          </div>
          <div className="input-row">
            <p className="clickable" onClick={() => super.changeState("forgotPassword")}> 
                Forgot your password?
            </p>
            <p className="clickable" onClick={() => super.changeState("signUp")}>
              Create account
            </p>
          </div>
        </form>
      </div>
    );
  }
}