/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://ifhmpfoxknfvdpuapyuvkmx54q.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_content_delivery_bucket": "loyalty-rover-amplify-react-app-prod",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "http://loyalty-rover-amplify-react-app-prod.s3-website.us-east-2.amazonaws.com"
};


export default awsmobile;
