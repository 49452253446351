import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

// LR Components
import './style.scss';
import logo from '../../assets/logo-header-regular.png';
import { CustomSignIn } from '../../components/customSignIn/CustomSignIn';
import CustomGreetings from '../../components/customGreetings/CustomGreetings';

// Amplify
import Amplify from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { Authenticator, SignIn, Greetings } from 'aws-amplify-react';

Amplify.configure(awsconfig);

const App: FunctionComponent<any> = (props: any) => {
  let authEnabled = false;

  return (
    <>
      <header>
        <img src={logo} alt="logo" />
      </header>
      { authEnabled &&
        
        <div className="Demo">
          <Authenticator hide={[Greetings, SignIn]} amplifyConfig={awsconfig}>
            <CustomGreetings />
            <em>Auth Test Success!</em>
            <CustomSignIn />
          </Authenticator>
        </div>
      }
      { !authEnabled &&
        <div className="Demo">
          <em>No Auth Test Success!</em>
        </div>
      }
    </>
  );
}

export default connect<any, any>(
  // map state to props
  (state: any): any => ({ ...state }),
  // map dispatch to props
  (dispatch: Function): any => ({
    updateMessage: (message: string) => dispatch({ action: message })
  })
)(App);